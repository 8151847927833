<template>
     <div class="material_con">
          <div class="material_code">
               <div class="title">{{L['物料编码']}}</div>
               <div class="mat_code_con">


                    <div class="search_con flex_row_start_center">
                         <div class="search_item flex_row_start_center">
                              <span>{{L['产品名称：']}}</span>
                              <div class="wrap_search">
                                   <input type="text" :placeholder="L['请输入产品名称']" v-model="goodsName">
                              </div>
                         </div>
                         <div class="search_item flex_row_start_center">
                              <span>{{L['订货编码：']}}</span>
                              <div class="wrap_search">
                                   <input type="text" :placeholder="L['请输入订货编码']" v-model="orderCode">
                              </div>
                         </div>
                         <div class="search_item flex_row_start_center">
                              <span>{{L['物料编码：']}}</span>
                              <div class="wrap_search">
                                   <input type="text" :placeholder="L['请输入物料编码']" v-model="productCode">
                              </div>
                         </div>

                         <div class="search_item flex_row_start_center">
                              <span>{{L['供应商：']}}</span>
                              <div class="">
                                   <el-select v-model="selStoreId" class="m-2" :placeholder="L['请选择']" size="small">
                                        <el-option v-for="item in vnList" :key="item.storeId" :label="item.storeName"
                                             :value="item.storeId">
                                        </el-option>
                                        <div></div>
                                   </el-select>
                              </div>
                         </div>
                    </div>


                    <div class="search_con flex_row_start_center">
                         <div class="btn_1" @click="search('do')">{{L['搜索']}}</div>
                         <div class="btn_2" @click="search('reset')">{{L['重置']}}</div>
                    </div>



                    <div class="options_con flex_row_start_center">
                         <div class="option1" @click="showBatch('addCode')">{{L['新增物料编码']}}</div>
                         <div class="option1" @click="showBatch('tmpImport')">{{L['模版导入']}}</div>
                         <div class="option1" @click="showBatch('quiclAdd')">{{L['快速添加']}}</div>
                    </div>



                    <div class="matCode_table" id="matCode_table">
                         <el-table ref="multipleTable" :data="matCodeList" style="width: 100%"
                              @selection-change="handleSelectionChange">
                              <el-table-column type="selection" width="45" v-if="batchFlag" />
                              <el-table-column property="goodsName" :label="L['产品名称']" width="200" />
                              <el-table-column property="specValues" :label="L['规格']" width="150" :formatter="formatter" />
                              <el-table-column property="orderCode" :label="L['订货编码']" width="150" align="center"
                                   :formatter="formatter" />
                              <el-table-column property="storeName" :label="L['供应商']" width="130" align="center" />
                              <el-table-column property="productCode" :label="L['物料编码']" width="130" align="center" />
                              <el-table-column :label="L['启用状态']" width="100" align="center">
                                   <template #default="scope">
                                        <div class="switch_wrap" @click="operateContent('edit', scope.row)"
                                             v-show="!loading">
                                             <el-switch v-model="scope.row.isEnable" :active-value="1"
                                                  :inactive-value="0"></el-switch>
                                        </div>
                                   </template>
                              </el-table-column>
                              <el-table-column :label="L['操作']" align="center">
                                   <template #default="scope">
                                        <div class="options">
                                             <span class="txt17" @click="openEdit(scope.row)">{{L['编辑']}}</span>
                                             <el-popconfirm :title="L['确定删除此编码？']" :confirm-button-text="L['确定']" :cancel-button-text="L['取消']"
                                                  @confirm="operateContent('del', scope.row)">
                                                  <template #reference>
                                                       <span class="txt17">{{L['删除']}}</span>
                                                  </template>
                                             </el-popconfirm>
                                        </div>
                                   </template>
                              </el-table-column>
                         </el-table>
                         <!-- 分页 -->
                         <div class="flex_row_center_center sld_pagination">
                              <el-pagination @current-change="pageChange" v-model:currentPage="page.current"
                                   :page-size="page.pageSize" layout="prev, pager, next, jumper" :total="page.total"
                                   :hide-on-single-page="true">
                              </el-pagination>
                         </div>
                    </div>
               </div>
          </div>

          <el-dialog v-model="visibleFlag.tmpImport" width="902px" lock-sroll="false" :before-close="handleClose">
               <div class="section1 flex-col">
                    <div class="mod1 flex-col">
                         <div class="outer1 flex-col">
                              <div class="bd2 flex-row">
                                   <span class="word1">{{L['导入物料编码']}}</span>
                                   <span class="el-icon-close" @click="handleClose" style="cursor: pointer"></span>
                              </div>
                         </div>

                         <div class="outer2_new">
                              <div class="wrap2 flex-col">
                                   <span class="word1">{{L['操作提示']}}</span>
                                   <div class="main15 flex-row">
                                        <span class="info2">.</span>
                                        <span class="word2">{{L['请先下载导入模版，并按照批注中的要求填写数据，未按要求填写将会导致导入失败']}}</span>
                                   </div>
                                   <div class="main15 flex-row">
                                        <span class="info2">.</span>
                                        <span class="word2">{{L['请选择.xls文件，每次只能导入一个文件，建议每次导入不超过']}}&nbsp;100&nbsp;{{L['条产品数据']}}</span>
                                   </div>
                              </div>
                              <div class="wrap3 flex_column_center_center">
                                   <div class="wrap_step flex_row_center_center">
                                        <div class="steps_row flex_row_start_center">
                                             <div :class="{ circle1: true, circle3: carouselIdx.finished >= 0 }">
                                                  <span :class="{ 'el-icon-check': carouselIdx.finished >= 0 }">{{
                                                       carouselIdx.finished >= 0 ? '' : 1
                                                  }}</span>
                                             </div>
                                             <span class="step_txt1">{{L['下载导入模版']}}</span>
                                        </div>
                                        <div class="line1"></div>
                                        <div class="steps_row flex_row_start_center">
                                             <div
                                                  :class="{ circle1: true, circle2: carouselIdx.current < 1, circle3: carouselIdx.finished >= 1 }">
                                                  <span :class="{ 'el-icon-check': carouselIdx.finished >= 1 }">{{
                                                       carouselIdx.finished >= 1 ? '' : 2
                                                  }}</span>
                                             </div>
                                             <span :class="{ step_txt1: true, txt2: carouselIdx.current < 1 }">{{L['上传文件']}}</span>
                                        </div>
                                        <div class="line1"></div>
                                        <div class="steps_row flex_row_start_center">
                                             <div
                                                  :class="{ circle1: true, circle2: carouselIdx.current < 2, circle3: carouselIdx.finished == 2 }">
                                                  {{ carouselIdx.finished == 2 ? '' : 3 }}</div>
                                             <span :class="{ step_txt1: true, txt2: carouselIdx.current < 2 }">{{L['完成']}}</span>
                                        </div>
                                   </div>
                                   <div class="step_swiper">
                                        <el-carousel arrow="never" height="150" :initial-index="carouselIdx.current"
                                             :autoplay="false" ref="carousel" indicator-position="none" :loop="false">
                                             <el-carousel-item name="first">
                                                  <div class="step1 flex_column_center_center">
                                                       <div class="option2" @click="downTemplateFile">{{L['下载标准模版']}}>></div>
                                                       <div class="next" @click="nextMove(0)">{{L['下一步']}}</div>
                                                  </div>
                                             </el-carousel-item>
                                             <el-carousel-item>
                                                  <div class="step2 flex_column_start_center">
                                                       <div class="upload flex_column_center_center" id="dropZone">
                                                            <label for="upload">
                                                                 <div class="el-icon-plus"></div>
                                                            </label>
                                                            <input type="file" name="" id="upload"
                                                                 accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                                 @change="getFile" ref="inputRef">
                                                            <div class="txt4">{{L['点击上传或者拖拽文件到该区域即可']}}</div>
                                                       </div>
                                                       <div class="errMsg" v-if="errMsg.errFlag">
                                                            <span class="errTxt1">{{L['上传失败！']}}</span>
                                                            <span class="errTxt2">{{L['您可以']}}</span>
                                                            <a class="errTxt3" @click="downErrFile">{{L['下载错误表格']}}</a>
                                                            <span class="errTxt2">，{{L['查看错误原因，修改后重新上传。']}}</span>
                                                       </div>
                                                  </div>
                                             </el-carousel-item>
                                             <el-carousel-item>
                                                  <div class="step3 flex_column_center_center">
                                                       <div class="step3_txt1">{{L['导入成功！']}}</div>
                                                       <div class="step3_txt2">{{L['您可以关闭该弹框查看已导入的物料编码，或是继续导入。']}}</div>
                                                       <div class="next" @click="nextMove(2)">{{L['继续导入']}}</div>
                                                  </div>
                                             </el-carousel-item>
                                        </el-carousel>
                                   </div>
                              </div>
                         </div>

                    </div>
               </div>
          </el-dialog>



          <el-dialog v-model="visibleFlag.addCode" width="600px" lock-sroll="false" :before-close="beforClose">
               <div class="section3 flex-col">
                    <div class="mod1 flex-col">
                         <div class="outer1 flex-col">
                              <div class="bd2 flex-row">
                                   <span class="word1">{{ codeTitle.title }}</span>
                                   <span class="el-icon-close" @click="cancelAdd" style="cursor: pointer"></span>
                              </div>
                         </div>
                         <div class="outer_ss flex_row_center_center">
                              <table>
                                   <tr class="row1">
                                        <td class="td_title"><i>*</i><span>{{L['订货编码：']}}</span></td>
                                        <td>
                                             <div v-if="codeTitle.type == 2">
                                                  <span>{{ newMatCode.orderCode }}</span>
                                             </div>

                                             <div class="relative flex_row_start_center" v-else>
                                                  <el-input type="text" :placeholder="L['请输入产品的订货编码']"
                                                       v-model="newMatCode.orderCode" @input="orderCodeInput">
                                                  </el-input>

                                                  <div class="do_search" @click="querySearch">
                                                       <span class="el-icon-search"></span>
                                                  </div>
                                             </div>


                                        </td>
                                   </tr>
                                   <tr class="row1">
                                        <td class="td_title"><span>{{L['产品信息：']}}</span></td>
                                        <td class="relative">
                                             <div class="product_info flex_row_start_center" v-if="doSearchProduct">
                                                  <template v-if="queryProduct">
                                                       <div class="product_image">
                                                            <img :src="queryProduct.mainImage">
                                                       </div>
                                                       <div class="product_text">
                                                            <div class="name">{{ queryProduct.goodsName }}</div>
                                                            <div class="spec">{{ queryProduct.specValues }}</div>
                                                            <div class="store_name">{{ queryProduct.storeName }}</div>
                                                       </div>
                                                  </template>
                                                  <span class="no_product_code" v-else>{{L['货品不存在']}}</span>
                                             </div>
                                             <span class="no_product_code" v-else>{{L['暂无数据']}}</span>
                                        </td>
                                   </tr>
                                   <tr class="row1">
                                        <td class="td_title"><i>*</i><span>{{L['物料编码：']}}</span></td>
                                        <td>
                                             <el-input type="text" :placeholder="L['请输入物料编码']" v-model="newMatCode.matCode"
                                                  maxlength="50"></el-input>
                                        </td>
                                   </tr>
                                   <tr class="row1">
                                        <td class="td_title"><span>{{L['是否启用：']}}</span></td>
                                        <td>
                                             <el-switch v-model="newMatCode.isEnable"></el-switch>
                                        </td>
                                   </tr>
                              </table>
                         </div>
                         <div class="outer3 flex-col"></div>
                         <div class="outer4 flex-row">
                              <button class="outer5 flex-col" @click="cancelAdd">
                                   <span class="word4">{{L['取消']}}</span>
                              </button>
                              <button class="outer6 flex-col" @mousedown="addMatCode">
                                   <span class="word5">{{L['确定']}}</span>
                              </button>
                         </div>
                    </div>
               </div>
          </el-dialog>

          <el-dialog v-model="visibleFlag.quiclAdd" :before-close="closeQuick" width="934px" lock-sroll="false" top="8vh">
               <div class="section1 flex-col">
                    <div class="mod1 flex-col">
                         <div class="outer1 flex-col">
                              <div class="bd2 flex-row">
                                   <span class="word1">{{L['添加物料编码']}}</span>
                                   <span class="el-icon-close" @click="closeQuick" style="cursor: pointer"></span>
                              </div>
                         </div>
                         <div class="outer2 flex-row">
                              <div class="outer2_item" :class="{ active: quickInfo.index == 0 }" @click="changeQuick(0)">
                                   {{L['由购物车添加']}}</div>
                              <div class="outer2_item" :class="{ active: quickInfo.index == 1 }" @click="changeQuick(1)">
                                   {{L['由已购产品添加']}}</div>
                              <div class="outer2_item" :class="{ active: quickInfo.index == 2 }" @click="changeQuick(2)">
                                   {{L['由收藏产品添加']}}</div>
                         </div>
                         <div class="outer22 flex-row">
                              <div class="outer22_item flex-row">
                                   <span>{{L['产品名称']}}:</span>
                                   <input v-model="quickInfo.goodsName" :placeholder="L['请输入产品名称']" />
                              </div>
                              <div class="outer22_item flex-row">
                                   <span>{{L['订货编码']}}:</span>
                                   <input v-model="quickInfo.orderCode" :placeholder="L['请输入订货编码']" />
                              </div>
                              <div class="outer22_search" @click="searchQuick">{{L['搜索']}}</div>
                              <div class="outer22_reset" @click="resetQuick">{{L['重置']}}</div>
                         </div>
                         <div class="outer222 flex-row">
                              <el-checkbox class="outer222_l1" v-model="quickInfo.checked" @change="checkSelect(1)">
                              </el-checkbox>
                              <div class="outer222_l2">{{L['产品信息']}}</div>
                              <div class="outer222_l3">{{L['订货编码']}}</div>
                              <div class="outer222_l4">{{L['物料编码']}}</div>
                         </div>
                         <div class="outer223_box">
                              <div class="outer223 flex-row" v-for="item in quickInfo.list" :key="item.orderCode">
                                   <div v-if="item.disabled" class="outer223_l1" style="width:14px;"></div>
                                   <el-checkbox v-else class="outer223_l1" v-model="item.checked"
                                        @change="checkSelect(2)"></el-checkbox>
                                   <img class="outer223_l2" :src="item.goodsImage" />
                                   <div class="outer223_l3">
                                        <p>{{ item.goodsName }}</p>
                                        <p class="specValues">{{ item.specValues }}</p>

                                   </div>
                                   <div class="outer223_l4">{{ item.orderCode }}</div>
                                   <div class="outer223_l5" v-if="!item.checked && item.productCode">
                                        {{ item.productCode }}</div>
                                   <input class="outer223_l6" v-else-if="!item.checked && !item.productCode"
                                        v-model="item.productCode" disabled />
                                   <input class="outer223_l6" v-else v-model="item.productCode"
                                        @input="((val) => { changeCode(val, item) })" maxlength="50" />
                              </div>
                              <SldCommonEmpty v-show="quickInfo.list.length == 0" totalWidth="740" :tip="L['暂无产品']" />
                         </div>
                         <!-- 分页 -->
                         <div class="flex_row_center_center sld_pagination">
                              <el-pagination v-model:currentPage="quickInfo.current" @current-change="quickChange"
                                   :page-size="quickInfo.pageSize" layout="prev, pager, next, jumper"
                                   :total="quickInfo.total" :hide-on-single-page="true">
                              </el-pagination>
                         </div>
                         <div class="outer224" @click="submitQuick">{{L['确定']}}</div>
                    </div>
               </div>
          </el-dialog>
     </div>
</template>

<script>
import { ref, getCurrentInstance, onMounted, reactive, toRefs } from 'vue';
import { ElMessage } from 'element-plus';
import SldCommonEmpty from '@/components/SldCommonEmpty';
export default {
     components: { SldCommonEmpty },
     setup() {
          const visibleFlag = reactive({
               addCode: false,
               tmpImport: false,
               tmpView: false,
               quiclAdd: false
          })
          const idxNum = ref(0)
          const { proxy } = getCurrentInstance()
          const L = proxy.$getCurLanguage()
          const vnList = ref([])
          const matCodeList = ref([])
          const page = ref({})
          const matListParam = reactive({
               current: 1,
               pageSize: 10
          })
          const newMatCode = reactive({
               ordeCode: '',
               matCode: '',
               isEnable: true,
          })
          const curVn = ref(0)
          const specList = ref([])
          const codeTitle = reactive({
               title: '',
               type: null
          })
          const uploading = ref(false)
          const carouselIdx = reactive({
               current: 0,
               finished: -1
          })
          const errMsg = reactive({
               errFlag: false,
               errFile: ''
          })
          const showAs = ref(true)

          const searchDto = reactive({
               goodsName: '',
               orderCode: '',
               productCode: '',
               selStoreId: '',
          })

          const queryProduct = ref({})
          const doSearchProduct = ref(false)
          const querySearch = () => {
               showAs.value = true
               if (!newMatCode.orderCode) {
                    ElMessage(L['请输入订货编码'])
                    return
               }
               let params = {
                    orderCode: newMatCode.orderCode,
               }
               proxy.$post('v3/goods/front/goods/enterpriseCode/productInfo', params).then(res => {
                    doSearchProduct.value = true

                    if (res.state == 200) {
                         if (res.data) {
                              queryProduct.value = res.data
                         } else {
                              queryProduct.value = null
                         }
                    } else {
                         queryProduct.value = null
                    }
               })
          }

          const showBatch = (property) => {
               if (property == 'tmpImport') {
                    errMsg.errFlag = false
                    carouselIdx.current = 0
                    carouselIdx.finished = -1
                    setTimeout(() => {
                         fileDrag()
                    }, 500)
               } else if (property == 'addCode') {
                    codeTitle.title = L['新增物料编码']
                    codeTitle.type = 1
                    newMatCode.orderCode = ''
                    newMatCode.matCode = ''
                    newMatCode.isEnable = true
                    queryProduct.value = ''
                    delete newMatCode.id
               } else if (property == 'quiclAdd') {
                    getQuickList();
               }
               visibleFlag[property] = !visibleFlag[property]
          }

          const getVendorList = () => {
               let paramVendor = {
                    current: 1,
               }
               proxy.$get('v3/seller/front/store/storeList', paramVendor).then(res => {
                    if (res.state == 200) {
                         vnList.value = res.data
                    }
               })
          }

          const loading = ref(false)
          const getMatCodeList = (vn, type) => {
               loading.value = true
               let loadingInstance = proxy.$loading({ target: '#matCode_table' })
               proxy.$get('v3/goods/front/goods/enterpriseCode/list', matListParam).then(res => {
                    loadingInstance.close(0)
                    loading.value = false
                    if (res.state == 200) {
                         matCodeList.value = res.data.list
                         page.value = res.data.pagination
                    }
               })
          }

          const operateContent = (type, data) => {
               let url, param
               if (type == 'edit') {
                    // let isShowFlag = !data.isEnable ? 1 : 0
                    param = {
                         id: data.id,
                         isEnable: data.isEnable,
                         productCode: data.productCode
                    }
                    url = 'v3/goods/front/goods/enterpriseCode/update'
               } else {
                    param = {
                         id: data.id,
                    }
                    url = 'v3/goods/front/goods/enterpriseCode/delete'
               }
               proxy.$post(url, param).then(res => {
                    ElMessage(res.msg)
                    if (res.state == 200) {
                         if (type == 'del') {
                              getMatCodeList()
                         }
                    }
               })
          }

          const pageChange = (e) => {
               matListParam.current = e
               getMatCodeList({}, 'pageChange')
          }

          const vnSel = (vn, index) => {
               idxNum.value = index
               search('clear')
               getMatCodeList(vn, 'vnChange')
          }

          const addMatCode = () => {
               if (!newMatCode.orderCode) {
                    ElMessage(L['请填订货编码'])
                    return
               }

               if (!doSearchProduct.value) {
                    ElMessage(L['请先获取产品信息'])
                    return
               }


               if (!newMatCode.matCode.trim()) {
                    ElMessage(L['请填物料编码'])
                    return
               }

               let param = {
                    orderCode: newMatCode.orderCode,
                    isEnable: newMatCode.isEnable ? 1 : 0,
                    productCode: newMatCode.matCode.trim(),
               }
               let url = ''
               if (codeTitle.type == 1) {
                    url = 'v3/goods/front/goods/enterpriseCode/add'
               } else {
                    param.id = newMatCode.id
                    url = 'v3/goods/front/goods/enterpriseCode/update'
               }

               proxy.$post(url, param).then(res => {
                    if (res.state == 200) {
                         ElMessage.success(res.msg)
                         visibleFlag.addCode = false
                         doSearchProduct.value = false
                         getMatCodeList()
                    } else {
                         ElMessage(res.msg)
                    }
               })
          }

          const fileDrag = () => {
               var dz = document.getElementById('dropZone');
               dz.ondragover = function (e) {
                    //阻止浏览器默认打开文件的操作  
                    e.preventDefault();
                    e.stopPropagation();
                    //拖入文件后边框颜色变红  
                    e.dataTransfer.dropEffect = 'copy';
               }
               dz.ondragleave = function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    //恢复边框颜色  
                    this.style.borderColor = 'gray';
               }
               dz.ondrop = function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    //恢复边框颜色  
                    this.style.borderColor = '#d9d9d9';
                    //阻止浏览器默认打开文件的操作  
                    e.preventDefault();
                    let filesTarget = e.dataTransfer.files;
                    uploadFile(filesTarget)
               }
          }

          const getFile = (e) => {
               uploadFile(e.target.files)
          }

          const uploadFile = (info) => {
               let file = info[0]
               let typelist = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
               let index = typelist.findIndex(i => file.type == i)
               if (index < 0) {
                    ElMessage(L['上传的文件格式不正确'])
                    return
               }
               if (file) {
                    uploading.value = true
                    proxy.$post('v3/goods/front/goods/enterpriseCode/import', {
                         file
                    }, 'form').then(res => {
                         proxy.$refs.inputRef.value = ""
                         if (res.state == 200) {
                              uploading.value = false
                              errMsg.errFlag = false
                              nextMove(1)
                              getMatCodeList()
                         } else if (res.state == 267) {
                              errMsg.errFlag = true
                              errMsg.errFile = res.data
                              uploading.value = false
                         } else {
                              ElMessage(res.msg)
                         }
                    })
               }
          }

          const downTemplateFile = () => {
               proxy.$get('v3/goods/front/goods/enterpriseCode/download', {}, 'blob').then(res => {
                    let tmp = new Blob([res], { type: 'application/vnd.ms-excel' })
                    const aLink = document.createElement('a');
                    document.body.appendChild(aLink);
                    aLink.style.display = 'none';
                    aLink.href = window.URL.createObjectURL(tmp);
                    aLink.setAttribute('download', L['标准模板']);
                    aLink.click();
                    document.body.removeChild(aLink);
               })
          }

          const downErrFile = () => {
               const aLink = document.createElement('a');
               document.body.appendChild(aLink);
               aLink.style.display = 'none';
               aLink.href = errMsg.errFile;
               aLink.setAttribute('download', L['错误表格']);
               aLink.click();
               document.body.removeChild(aLink);
          }



          const openEdit = (scopeItem) => {
               newMatCode.orderCode = scopeItem.orderCode
               newMatCode.matCode = scopeItem.productCode
               newMatCode.isEnable = scopeItem.isEnable == 1 ? true : false
               newMatCode.id = scopeItem.id
               visibleFlag.addCode = true
               codeTitle.title = L['编辑物料编码']
               codeTitle.type = 2
               querySearch()
          }

          const nextMove = (idx) => {
               if (idx == 0) {
                    carouselIdx.current++
                    carouselIdx.finished = 0
                    proxy.$refs.carousel.next()
               } else if (idx == 2) {
                    carouselIdx.current--
                    carouselIdx.finished = 0
                    proxy.$refs.carousel.prev()
               } else {
                    carouselIdx.finished = 1
                    carouselIdx.current++
                    proxy.$refs.carousel.next()
               }
          }

          const handleClose = () => {
               visibleFlag.tmpImport = false;
               if (carouselIdx.current != 0) {
                    proxy.$refs.carousel.setActiveItem(0)
               }
               carouselIdx.current = 0
               carouselIdx.finished = -1
          }

          const quickInfo = reactive({ //快速添加
               index: 0,
               current: 1,
               pageSize: 10,
               total: 1,
               goodsName: '',
               orderCode: '',
               list: [],
               confirm: [], //提交的数据
               confirmIds: '/',
               checked: false, //全选
               isSend: false, //防止多次提交
          });
          const closeQuick = () => {
               quickInfo.index = 0;
               quickInfo.current = 1;
               quickInfo.total = 1;
               quickInfo.goodsName = '';
               quickInfo.goodsSpec = '';
               quickInfo.list = [];
               quickInfo.confirm = [];
               quickInfo.confirmIds = '/';
               quickInfo.checked = false;
               quickInfo.isSend = false;
               visibleFlag.quiclAdd = false;
          }
          // 获取快速添加数据
          const getQuickList = () => {
               let param = {
                    current: quickInfo.current,
                    pageSize: quickInfo.pageSize
               }

               quickInfo.goodsName && (param.goodsName = quickInfo.goodsName) //产品名称
               quickInfo.orderCode && (param.orderCode = quickInfo.orderCode)

               let url = '';
               if (quickInfo.index == 0) { //购物车
                    url = 'v3/business/front/cart/list'
               } else if (quickInfo.index == 1) { //已购
                    url = 'v3/business/front/orderInfo/buyList'
               } else { //收藏
                    url = 'v3/member/front/followProduct/followList'
               }
               proxy.$get(url, param).then(res => {
                    if (res.data.list.length > 0) {
                         let num = 0;
                         res.data.list.forEach(item => {
                              if (item.productCode) {
                                   item.disabled = true;
                              } else {
                                   let arr = quickInfo.confirmIds.split('/');
                                   let index = arr.indexOf(item.productId.toString())
                                   if (quickInfo.confirmIds != '/' && index > 0) {
                                        item.productCode = quickInfo.confirm[index - 1].productCode
                                        item.checked = true;
                                        num++;
                                   } else {
                                        item.checked = false;
                                   }
                              }
                         })
                         quickInfo.list = res.data.list;
                         if (num == quickInfo.list.length) {
                              quickInfo.checked = true;
                         }
                    } else {
                         quickInfo.list = []
                    }
                    quickInfo.total = res.data.pagination.total;
               })
          };
          const checkSelect = (type) => { // 1: 全选  2: 单选
               if (quickInfo.list.length > 0) {
                    let all = 0;
                    let num = 0;
                    for (let i = 0; i < quickInfo.list.length; i++) {
                         if (quickInfo.list[i].disabled) {
                              continue;
                         } else {
                              all++;
                              if (type == 1) {
                                   quickInfo.list[i].checked = quickInfo.checked;
                              }
                              if (quickInfo.list[i].checked) {
                                   num++;
                              }
                              let arr = quickInfo.confirmIds.split('/');
                              let index = arr.indexOf(quickInfo.list[i].productId.toString())
                              if (index > 0 && !quickInfo.list[i].checked) {
                                   // 产品已存在 -- 删除
                                   quickInfo.confirm.splice(index - 1, 1)
                                   quickInfo.confirmIds = quickInfo.confirmIds.replace('/' + quickInfo.list[i].productId + '/', '/');
                                   i--;
                              } else if (index == -1 && quickInfo.list[i].checked) {
                                   // 产品不存在 -- 添加
                                   quickInfo.confirmIds += (quickInfo.list[i].productId + '/');
                                   quickInfo.confirm.push({
                                        productId: quickInfo.list[i].productId,
                                        productCode: quickInfo.list[i].productCode || '',
                                        orderCode: quickInfo.list[i].orderCode || ''
                                   });
                              }
                         }
                    }
                    if (type == 2) {
                         if (num == all && num > 0) {
                              quickInfo.checked = true;
                         } else {
                              quickInfo.checked = false;
                         }
                    }
               }
          };
          const changeCode = (e, item) => { //物料编码输入
               let arr = quickInfo.confirmIds.split('/');
               let index = arr.indexOf(item.productId.toString())
               if (index > 0) {
                    quickInfo.confirm[index - 1].productCode = item.productCode;
               }
          };
          const changeQuick = (index) => {
               if (quickInfo.index != index) {
                    quickInfo.index = index
                    quickInfo.current = 1
                    quickInfo.total = 0
                    quickInfo.list = []
                    quickInfo.confirm = []
                    quickInfo.confirmIds = '/'
                    quickInfo.checked = false
                    getQuickList();
               }
          };
          const searchQuick = () => {
               getQuickList();
          };
          const resetQuick = () => {
               quickInfo.current = 1;
               quickInfo.total = 0;
               quickInfo.list = [];
               quickInfo.confirm = []
               quickInfo.confirmIds = '/'
               quickInfo.checked = false;
               quickInfo.goodsName = '';
               quickInfo.orderCode = '';

               getQuickList();
          };
          const quickChange = (e) => {
               quickInfo.current = e
               quickInfo.checked = false
               getQuickList();
          };
          const submitQuick = () => {
               if (quickInfo.confirm.length == 0) {
                    ElMessage(L['请选择产品!'])
                    return;
               }
               let flag = true;
               quickInfo.confirm.forEach(item => {
                    if (!item.productCode) {
                         flag = false;
                    }
               })
               if (!flag) {
                    ElMessage(L['选中产品的物料编码不能为空!'])
                    return;
               } else if (quickInfo.isSend) {
                    return;
               }
               quickInfo.isSend = true;
               let config = { headers: { 'Content-Type': 'application/json' } }
               proxy.$post('v3/goods/front/goods/enterpriseCode/batchAdd', quickInfo.confirm, config).then(res => {
                    if (res.state == 200) {
                         ElMessage.success(res.msg);
                         closeQuick();
                         getMatCodeList();
                    } else {
                         quickInfo.isSend = false;
                         ElMessage(res.msg);
                    }
               })
          };


          const search = (type) => {
               let { productCode, goodsName, orderCode, selStoreId } = searchDto
               switch (type) {
                    case 'do': {
                         if (productCode) {
                              matListParam.productCode = productCode
                         } else {
                              delete matListParam.productCode
                         }

                         if (goodsName) {
                              matListParam.goodsName = goodsName
                         } else {
                              delete matListParam.goodsName
                         }


                         if (orderCode) {
                              matListParam.orderCode = orderCode
                         } else {
                              delete matListParam.orderCode
                         }


                         selStoreId && (matListParam.storeId = selStoreId)


                         matListParam.current = 1

                         getMatCodeList({}, 'select')
                         break

                    }


                    case 'reset': {
                         Object.keys(searchDto).forEach(item => {
                              searchDto[item] = ''
                              delete matListParam[item]
                         })
                         delete matListParam.storeId



                         getMatCodeList({}, 'select')

                    }


                    case 'clear': {
                         Object.keys(searchDto).forEach(item => {
                              searchDto[item] = ''
                              delete matListParam[item]
                         })
                    }
               }
          }
          const loadingInstance = ref(null)

          const formatter = (row, column, cellValue, index) => {
               return cellValue ? cellValue : '--'
          }

          const beforClose = (done) => {
               doSearchProduct.value = false
               done()
          }


          const cancelAdd = () => {
               visibleFlag.addCode = false
               doSearchProduct.value = false
          }


          const orderCodeInput = () => {
               if (!newMatCode.orderCode) {
                    queryProduct.value = ''
               }
          }

          onMounted(() => {
               getVendorList()
               getMatCodeList()
          })




          return {
               cancelAdd,
               beforClose,
               formatter,
               visibleFlag,
               showBatch,
               idxNum,
               vnList,
               matCodeList,
               operateContent,
               page,
               vnSel,
               pageChange,
               newMatCode,
               addMatCode,
               querySearch,
               specList,
               openEdit,
               codeTitle,
               downTemplateFile,
               getFile,
               carouselIdx,
               nextMove,
               errMsg,
               downErrFile,
               showAs,
               handleClose,
               closeQuick,
               quickInfo,
               getQuickList,
               changeQuick,
               checkSelect,
               changeCode,
               searchQuick,
               resetQuick,
               quickChange,
               submitQuick,
               ...toRefs(searchDto),
               search,
               loading,
               queryProduct,
               doSearchProduct,
               orderCodeInput,
               L
          }
     },




}
</script>

<style lang="scss">
.material_con {

     .el-dialog__header {
          display: none;
     }

     .el-dialog__body {
          padding: 0;
     }

     .has-gutter {
          background: #F4F4F4 !important;
     }

     .el-table thead tr {
          background-color: #F4F4F4;
     }

     .el-table th {
          background-color: #F4F4F4;
     }

     .row1 {
          .el-input {
               width: 338px;
          }

          td {
               vertical-align: middle !important;
          }
     }
}

.step_swiper {
     .el-carousel__container {
          position: relative;
          height: 170px;
     }

     .el-carousel__indicators {
          display: none;
     }
}
</style>
<style lang="scss" scoped>
.material_code {
     float: left;
     width: 1009px;
     background-color: #fff;
     margin-left: 15px;

     .title {
          height: 60px;
          border-bottom: 1px solid $colorI;
          line-height: 60px;
          padding-left: 20px;
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #666666;
     }

     .mat_code_con {
          padding: 5px 20px 20px;

          .vendor_l {
               border-bottom: 1px solid #F2F2F2;

               .vn_ind {
                    height: 48px;
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    color: #666666;
                    margin-right: 30px;
                    line-height: 48px;
                    cursor: pointer;
                    white-space: nowrap;

                    &.chosen {
                         color: $colorMain;
                         box-shadow: 0px -2px 0px $colorMain inset;
                    }
               }
          }

          .search_con {
               margin: 24px 0;

               .search_item {

                    margin-right: 20px;


                    span {
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                         font-weight: 400;
                         color: $colorTitle2;
                         white-space: nowrap;
                    }

                    .wrap_search {
                         height: 32px;
                         border: 1px solid #dcdfe6;
                         border-radius: 5px;
                         padding: 0 10px;

                         input {
                              border: none;
                              height: 30px;
                              line-height: 26px;
                              outline: none;
                              border-radius: 3px;
                              color: #999;
                              width: 124px;
                         }
                    }
               }

               .btn_1 {
                    font-size: 14px;
                    color: #fff;
                    width: 60px;
                    height: 30px;
                    background: $colorMain;
                    text-align: center;
                    line-height: 30px;
                    border-radius: 3px;
                    margin-right: 20px;
                    cursor: pointer;
               }

               .btn_2 {

                    font-size: 14px;
                    width: 60px;
                    height: 30px;
                    background: #fff;
                    border: 1px solid $colorTitle2;
                    text-align: center;
                    line-height: 30px;
                    border-radius: 3px;
                    cursor: pointer;

               }
          }

          .options_con {
               margin-top: 20px;

               .option1 {
                    color: #fff;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    padding: 8px 15px;
                    background: $colorMain;
                    margin-right: 20px;
                    cursor: pointer;
               }

               .option2 {
                    color: $colorMain;
                    border-bottom: 1px solid $colorMain;
                    font-size: 14px;
                    cursor: pointer;
               }
          }

          .matCode_table {
               margin-top: 25px;

               .options {
                    .txt17 {
                         display: inline-block;
                         padding: 0 5px;
                         border-left: 1px solid rgba(102, 102, 102, 1);
                         color: rgba(102, 102, 102, 1);
                         cursor: pointer;

                         &:first-child {
                              border-left: none;
                         }

                         &:hover {
                              color: $colorMain;
                         }
                    }
               }
          }

     }
}

.section1 {
     z-index: 4;
     /* height: 395px; */
     border-radius: 4px;
     background-color: rgba(255, 255, 255, 1);
     width: 100%;
     justify-content: flex-start;

     .mod1 {
          width: 100%;
          /* height: 395px; */

          .outer2_new {
               z-index: 9;
               /* height: 208px; */
               background-color: rgba(255, 255, 255, 1);
               align-self: center;
               width: 100%;
               justify-content: center;
               align-items: center;
               padding: 20px;
               /* .mod2 {
                         z-index: auto;
                         width: 182px;
                         height: 102px;

                         .img1 {
                              z-index: 10;
                              width: 50px;
                              height: 50px;
                              align-self: center;
                         }

                         .layer11 {
                              z-index: 16;
                              width: 182px;
                              height: 22px;
                              overflow-wrap: break-word;
                              text-align: left;
                              white-space: nowrap;
                              margin-top: 30px;
                              font-size: 0;

                              .word2 {
                                   font-size: 14px;
                                   font-family: PingFangSC-Regular;
                                   color: rgba(153, 153, 153, 1);
                                   line-height: 22px;
                                   overflow: hidden;
                                   text-overflow: ellipsis;
                              }

                              .word3 {
                                   font-size: 14px;
                                   font-family: PingFangSC-Regular;
                                   color: rgba(40, 95, 222, 1);
                                   line-height: 22px;
                                   overflow: hidden;
                                   text-overflow: ellipsis;
                                   cursor: pointer;
                              }
                         }
                    } */

               .wrap2 {
                    background-color: rgba(40, 95, 222, 0.03);
                    color: $colorMain;
                    opacity: 0.85;
                    padding: 10px;

                    .word1 {
                         font-size: 18px;
                         font-family: SourceHanSansCN-Regular;
                         white-space: nowrap;
                         line-height: 18px;
                         text-align: left;
                         margin-bottom: 5px;
                    }

                    .main15 {
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         white-space: nowrap;
                         text-align: left;
                         margin-top: 10px;
                    }
               }

               .wrap3 {
                    margin-top: 40px;

                    .wrap_step {
                         width: 100%;

                         .steps_row {
                              margin-right: 15px;

                              .circle1 {
                                   width: 40px;
                                   height: 40px;
                                   background: $colorMain;
                                   border-radius: 50%;
                                   font-size: 24px;
                                   font-family: HelveticaNeue;
                                   color: #FFFFFF;
                                   text-align: center;
                                   line-height: 40px;
                                   margin-right: 12px;

                                   &.circle2 {
                                        color: rgba(0, 0, 0, 0.25) !important;
                                        background: #fff !important;
                                        border: 1px solid rgba(0, 0, 0, 0.25);
                                   }

                                   &.circle3 {
                                        color: $colorMain !important;
                                        background: #fff !important;
                                        border: 1px solid $colorMain;
                                   }
                              }

                              .step_txt1 {
                                   color: $colorMain;
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Medium;
                                   white-space: nowrap;

                                   &.txt2 {
                                        color: rgba(0, 0, 0, 0.25);
                                   }
                              }
                         }

                         .line1 {
                              width: 150px;
                              height: 1px;
                              background: #999;
                              margin-right: 15px;

                         }
                    }

                    .step_swiper {
                         margin-top: 25px;
                         width: 100%;

                         .step1 {
                              width: 100%;
                              height: 150px;

                              .option2 {
                                   color: $colorMain;
                                   border-bottom: 1px solid $colorMain;
                                   font-size: 14px;
                                   cursor: pointer;
                              }

                         }

                         .step2 {
                              width: 100%;
                              height: 170px;

                              .upload {
                                   border: 2px dashed #eee;
                                   background: #FAFAFA;
                                   width: 690px;
                                   height: 150px;

                                   .el-icon-plus {
                                        font-size: 26px;
                                        font-weight: bolder;
                                        color: #BFBFBF;
                                   }

                                   .txt4 {
                                        margin-top: 20px;
                                   }
                              }

                              .errMsg {
                                   font-size: 12px;
                                   width: 100%;
                                   margin-top: 7px;
                                   padding-left: 80px;

                                   .errTxt1 {
                                        color: #EB1010;
                                   }

                                   .errTxt2 {
                                        color: #333333;
                                   }

                                   .errTxt3 {
                                        color: $colorMain;
                                        cursor: pointer;

                                        &:hover {
                                             text-decoration: underline;
                                        }
                                   }
                              }


                         }

                         .step3 {
                              width: 100%;
                              height: 170px;

                              .step3_txt1 {
                                   font-size: 16px;
                                   font-weight: 600;
                                   color: #333333;
                              }

                              .step3_txt2 {
                                   font-size: 14px;
                                   font-weight: 400;
                                   color: #666666;
                                   margin-top: 20px;
                              }

                         }

                         .next {
                              margin-top: 20px;
                              width: 90px;
                              height: 34px;
                              background: $colorMain;
                              border-radius: 2px;
                              color: rgba(255, 255, 255, 1);
                              font-size: 14px;
                              line-height: 34px;
                              text-align: center;
                              cursor: pointer;
                         }

                    }

               }
          }

          .outer3 {
               z-index: 5;
               width: 100%;
               height: 1px;
               background: #f7f7f7;
               margin-top: 20px;
          }

          .outer2 {
               border-bottom: 1px solid #E8E8E8;
               margin: 20px;

               .outer2_item {
                    width: 148px;
                    height: 38px;
                    line-height: 38px;
                    text-align: center;
                    color: #000000;
                    font-size: 15px;
                    font-family: SourceHanSansCN-Regular;
                    border-top: 1px solid #E8E8E8;
                    border-left: 1px solid #E8E8E8;
                    cursor: pointer;

                    &:nth-child(3) {
                         border-right: 1px solid #E8E8E8;
                    }

                    &.active,
                    &:hover {
                         color: $colorMain;
                         background: #F2F6FF;
                    }
               }
          }

          .outer22 {
               padding-left: 20px;
               padding-right: 20px;
               align-items: center;

               .outer22_item {
                    margin-right: 20px;

                    span {
                         line-height: 30px;
                         color: rgba(0, 0, 0, 0.85);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         margin-right: 12px;
                         flex-shrink: 0;
                    }

                    input {
                         width: 180px;
                         padding-left: 8px;
                         height: 30px;
                         font-size: 13px;
                         border: 1px solid #D9D9D9;
                    }
               }

               .outer22_search,
               .outer22_reset {
                    width: 60px;
                    height: 30px;
                    line-height: 30px;
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular;
                    text-align: center;
                    cursor: pointer;
               }

               .outer22_search {
                    color: #FFFFFF;
                    background: $colorMain;
                    border: 1px solid $colorMain;
                    margin-left: 26px;
               }

               .outer22_reset {
                    color: rgba(0, 0, 0, 0.65);
                    border: 1px solid #D9D9D9;
                    margin-left: 25px;
               }
          }

          .outer222 {
               width: 890px;
               height: 40px;
               line-height: 40px;
               background: #F4F4F4;
               margin: 20px auto 0;

               .outer222_l1 {
                    margin-left: 34px;
               }

               .outer222_l2 {
                    margin-left: 200px;
               }

               .outer222_l3 {
                    margin-left: 240px;
               }

               .outer222_l4 {
                    margin-left: 150px;
               }
          }

          .outer223_box {
               width: 890px;
               margin: 0 auto;
               max-height: 294px;
               overflow-y: auto;

               .sld_common_empty {
                    height: 290px !important;
                    padding-top: 85px !important;
               }
          }

          .outer223 {
               height: 98px;
               padding-top: 20px;
               padding-bottom: 20px;
               border: 1px solid #F0F0F0;
               border-top: none;
               align-items: center;

               .outer223_l1 {
                    margin-left: 34px;
                    margin-right: 65px;
               }

               .outer223_l2 {
                    width: 57px;
                    height: 57px;
                    margin-right: 15px;
                    flex-shrink: 0;
               }

               .outer223_l3,
               .outer223_l4,
               .outer223_l5 {
                    width: 260px;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-clamp: 2;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    word-break: break-all;
               }

               .outer223_l3 {
                    margin-right: 15px;
               }

               .outer223_l4 {
                    width: 220px;
                    text-align: center;
                    margin-right: 15px;
               }

               .outer223_l5 {
                    display: block;
                    width: 150px;
                    text-align: center;
                    padding-left: 3px;
                    padding-right: 3px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
               }

               .outer223_l6 {
                    width: 150px;
                    height: 34px;
                    line-height: 34px;
                    margin-left: 6px;
                    padding-left: 3px;
                    padding-right: 3px;
               }
          }

          .outer224 {
               width: 116px;
               height: 34px;
               line-height: 34px;
               color: #FFFFFF;
               font-size: 14px;
               font-family: PingFangSC-Regular;
               text-align: center;
               background: $colorMain;
               border-radius: 2px;
               cursor: pointer;
               margin: 25px auto 20px;
          }
     }
}

.outer1 {
     z-index: 6;
     height: 46px;
     border-radius: 4px 4px 0 0;
     background-color: rgba(248, 248, 248, 1);
     width: 100%;
     justify-content: center;
     align-items: center;
     padding: 0 20px;

     .bd2 {
          width: 100%;
          justify-content: space-between;

          .word1 {
               z-index: 24;
               width: 82px;
               height: 14px;
               display: block;
               overflow-wrap: break-word;
               color: rgba(51, 51, 51, 1);
               font-size: 14px;
               letter-spacing: -0.33764705061912537px;
               font-family: SourceHanSansCN-Medium;
               white-space: nowrap;
               line-height: 14px;
               text-align: center;
               margin-top: 1px;
          }

          .label1 {
               z-index: 25;
               width: 16px;
               height: 16px;
          }

          .el-icon-close {
               font-size: 18px;
               color: #C4C4C4;
          }
     }
}

.outer5 {
     height: 34px;
     border-radius: 2px;
     border: 1px solid rgba(217, 217, 217, 1);
     background-color: rgba(255, 255, 255, 1);
     width: 90px;
     justify-content: center;
     align-items: center;
     cursor: pointer;
     margin-right: 20px;

     .word4 {
          color: rgba(0, 0, 0, 0.65);
          font-size: 14px;
          line-height: 20px;
          text-align: center;
     }
}

.outer4 {
     z-index: auto;
     width: 100%;
     height: 34px;
     justify-content: center;
     margin: 15px 0
}

.outer6 {
     height: 34px;
     border-radius: 2px;
     background-color: $colorMain;
     width: 90px;
     justify-content: center;
     align-items: center;
     border: none;
     cursor: pointer;

     .word5 {
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: -0.33764705061912537px;
          line-height: 20px;
          text-align: center;
     }
}

.section2 {
     width: 790px;

     .outer_s {
          padding: 20px;
     }
}

.group2 {
     z-index: 16;
     height: 148px;
     border-radius: 3px 3px 0 0;
     align-self: center;
     justify-content: flex-start;

     .bd1 {
          .outer11 {
               height: 40px;
               border-radius: 3px 3px 0 0;
               background: #eee;
               justify-content: center;
               align-items: flex-start;
               padding-left: 30px;
               border: 1px solid #eee;

               .outer22 {
                    z-index: auto;
                    width: 627px;
                    height: 14px;

                    .txt2 {
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                    }

                    .word1 {
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         margin-left: 315px;
                    }

                    .word2 {
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         margin-left: 144px;
                    }
               }
          }
     }
}

.outer33 {
     z-index: auto;
     padding: 14px 20px;
     border: 1px solid #eee;
     border-top: none;

     .info1 {
          width: 285px;
          color: rgba(102, 102, 102, 1);
          font-size: 14px;
          font-family: SourceHanSansCN-Regular;
     }

     .word3 {
          z-index: 22;
          width: 140px;

          color: rgba(102, 102, 102, 1);
          font-size: 14px;
          font-family: SourceHanSansCN-Regular;
          white-space: nowrap;

          margin-left: 92px;
     }

     .info2 {
          z-index: 21;
          width: 94px;

          color: rgba(102, 102, 102, 1);
          font-size: 14px;
          font-family: SourceHanSansCN-Regular;

          text-align: left;
          margin-left: 60px;
     }
}

.section3 {
     .outer_ss {
          padding-top: 20px;
          margin-bottom: 60px;

          .row1 {
               height: 56px;
          }

          .td_title {
               text-align: right;
               font-size: 14px;
               font-family: SourceHanSansCN-Regular, SourceHanSansCN;
               font-weight: 400;
               color: #333333;

               i {
                    color: $colorMain2;
                    margin-right: 4px;
               }
          }

          .el-input__inner {
               width: 338px;
               font-size: 13px;
               padding-right: 0;
               border-radius: 2px;
               padding-bottom: 1px;
               height: 34px;
          }
     }
}

#upload {
     display: none;
}

.relative {
     position: relative;

     .pop_over {
          position: absolute;
          width: 338px;
          max-height: 450px;
          overflow: auto;
          top: 55px;
          z-index: 90;
          background: #fff;
          border-radius: 4px;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);

          .pop_item {
               padding: 10px 10px;
               cursor: pointer;

               &:hover {
                    background-color: #eee;
               }
          }
     }

     .do_search {
          width: 40px;
          height: 40px;
          border-radius: 10px;
          background-color: $colorMain;
          text-align: center;
          line-height: 40px;
          cursor: pointer;
          margin-left: 10px;

          .el-icon-search {
               font-size: 15px;
               color: #fff;

          }
     }
}

.product_info {
     .product_image {
          img {
               width: 60px;
               height: 60px;
               background: #FFFFFF;
               border: 1px solid #E2E2E2;
          }
     }

     .product_text {
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 60px;

          .name {
               font-size: 14px;
               font-family: SourceHanSansCN-Regular;
               line-height: 16px;
               color: #333333;
               white-space: nowrap;
               overflow: hidden;
               text-overflow: ellipsis;
               width: 260px;
          }

          .spec {
               font-size: 12px;
               font-family: SourceHanSansCN-Regular;
               line-height: 18px;
               color: $colorH;
          }

          .store_name {
               font-size: 12px;
               font-family: SourceHanSansCN-Regular;
               line-height: 18px;
               color: #666666;
          }
     }
}

.specValues {
     margin-top: 5px;
     color: #999;
     font-size: 12px;
}
</style>